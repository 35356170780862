
body {
  margin: 0;
  font-family: "ProximaSoft",-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* .swiper-button-next:after, .swiper-button-prev:after{
  color: rgb(40, 40, 189);
}

.swiper-button-next, .swiper-button-prev{
  color:rgb(168, 225, 225)
} */